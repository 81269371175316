.about-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    text-align: justify;
  }
  
  .about-container h2 {
    text-align: center;
    color: #FAA184;
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .about-container p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .image-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  
  .image-container img {
    width: 300px;
    object-fit: cover;
    margin: 0 10px;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  

  @media (max-width: 1200px) {
    .image-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 20px;
    }
  }