.contact-header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.contact-social {
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.contact-mail-social {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.contact {
  width: 60%;
}

.social {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.rectangle-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  flex-direction: row;
  flex-wrap: wrap;
}

.rectangle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  text-align: start;
  flex-wrap: nowrap;
  padding: 20px;
  width: 300px;
  margin-right: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #f4f4f4;
}

.rectangle h4 {
  margin-bottom: 10px;
}

.contact-icon {
  font-size: 60px;
  color: #FAA184;
}


.form-input {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  justify-content: space-evenly;
  width: 75%;
}

.input,
textarea {
  width: 75%;
  box-sizing: border-box;
  padding: 12px;
  border: 1px solid #ccc;
  resize: none;
  border-radius: 8px;
  background-color: #f8f8f8;
}

.email-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  font-size: 18px;
}

.icon1 {
  margin-right: 20px;
}

.mail-header {
  margin-top: 50px;
  color: blueviolet;
  font-size: 40px;
}

.send-button {
  padding-bottom: 1cm;
}

.contact Button {
  margin-bottom: 2cm;
}

.projects-header {
  display: flex;
  color: #FAA184;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (max-width: 1165px) {
  .contact-page {
    margin-left: 5%;
    max-width: 80%;
  }

  .contact-social {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-mail-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .projects-header {
    display: flex;
    color: #FAA184;
    flex-direction: row;
    font-size: 24px;
  }

  .contact {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .projects-header {
    display: flex;
    color: #FAA184;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  textarea {
    height: fit-content;
  }

  .contact Button {
    margin-bottom: 2cm;
  }

  .form-input {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    justify-content: space-evenly;
    width: 90%;
  }

  .input,
  textarea {
    width: 90%;
    box-sizing: border-box;
    padding: 12px;
    border: 1px solid #ccc;
    resize: none;
    border-radius: 8px;
    background-color: #f8f8f8;
  }
}