.footer-row {
    font-family: Roboto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 350px;
    flex-wrap: wrap;
}

.dev {
    height: 46px;
    background-color: black;
    color: white;
    font-family: 'Inter', sans-serif;
    align-items: center;
    display: flex;
    margin-top: 20px;
    font-size: 18px;
    justify-content: center;
}

.dev a {
    text-decoration: none;
    align-items: center;
    color: white;
    margin: 0 10px;
}

.column {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

.column-1 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer-services {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer {
    font-family: Roboto;
    color: black;
    background-color: #D7FBFF;
}

.mail-row {
    display: flex;
    flex-direction: row;
    color: black;
    text-decoration: none;
}

.mail-row:hover {
    text-decoration: underline;
}

.icon {
    color: black;
    padding-right: 10px;
    size: 32px;
}

@media (max-width: 720px) {
    .footer-row {
        font-family: Roboto;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 470px;
        flex-wrap: wrap;
    }

    .dev {
        height: 46px;
        background-color: black;
        color: white;
        font-family: Inter;
        align-items: center;
        text-decoration: none;
        display: flex;
        margin-top: 20px;
        font-size: 14px;
        justify-content: space-evenly;
    }

    .dev a {
        margin: 0 1px;
    }

    .column {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: start;
        margin-bottom: 20px;
    }

    .column-1 {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-bottom: 30px;
    }


    .mail-row:hover {
        text-decoration: underline;
    }

    .icon {
        color: black;
        padding-right: 10px;
        size: 32px;
    }
}