.services {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 30px;
}


.services h1 {
    font-family: Roboto;
    margin-top: 20px;
    color: #FAA184;
    font-size: 50px;
    font-weight: 700;
    line-height: 116%;
}

.card img {
    border-radius: 20px;
    width: 370px;
    height: 250px;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 110px;
    padding-right: 110px;
}

.card {
    display: flex;
    font-family: Roboto;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 370px;
    height: 500px;
    border-radius: 20px;
    background-color: #f4f4f4;
    padding-bottom: 20px;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.card-header {
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    color: #1AB3C5;
}

.description {
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: black;
}

.card p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.card .button {
    width: 196px;
}

