.Service3 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 110px;
    padding-top: 40px;
    padding-right: 150px;
    font-size: 18px;
}

.Service3-page {
    display: flex;
    flex-direction: column;
    padding-left: 120px;
    padding-top: 40px;
    padding-right: 150px;
    font-size: 18px;
}

.Service3 h1 {
    color: #FAA184;
}

.Service3-detail {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    margin-bottom: 20px;
    justify-content: space-evenly;
}

.Service-detail-image{
    margin-bottom: 20px;
    border-radius: 20px;
    width: 320px;
}

.Service-detail-image3{
    width: 320px;
    margin-right: 36px;
    border-radius: 20px;
    height:425px;
    margin-bottom: 100px;
}

.img-containers3{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}



@media only screen and (max-width: 900px) {

    .Service3-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 40px;
        font-size: 18px;
    }

    .Service3{
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 90%;
        padding: 0px;
        padding: 0px;
        font-size: 18px;
        text-align: center;
    }

    .Service3-detail {
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin: 0px;
        justify-content: center;
    }
    

    .img-containers3{
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Service-detail-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 0;
    }

    .Service-detail-image3 {
        flex-wrap: wrap;
        margin-right: 0px;
        border-radius: 20px;
        padding: 0;
        height: 425px;
        align-items: center;
        margin-bottom: 10px;
    }

}