.references {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.references h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #FAA184;
    margin-top: 20px;
}

.references-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.reference-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 680px;
    margin: 20px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
}

.reference-logo {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
    object-fit: contain;
}

.reference-item div {
    text-align: center;
}

.reference-item h4 {
    font-size: 1.5rem;
    margin: 5px 0;
}

.reference-item p {
    font-size: 1rem;
    margin: 5px 0;
}
