.Service4 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 110px;
    padding-top: 40px;
    padding-right: 150px;
    font-size: 18px;
}

.Service4-page {
    display: flex;
    flex-direction: column;
    padding-left: 120px;
    padding-top: 40px;
    padding-right: 150px;
    font-size: 18px;
}

.Service4 h1 {
    color: #FAA184;
}

.Service1-detail4 {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    margin-bottom: 20px;
    justify-content: space-evenly;
}

.img-containers{
    display: flex;
    flex-direction: column;
}
 
.Service-detail-image{
    margin-bottom: 20px;
    border-radius: 20px;
    width: 320px;
}

.Service-detail-image4{
    width: 320px;
    margin-right: 36px;
    border-radius: 20px;
    height:425px;
    margin-bottom: 100px;
}

.img-containers4{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}



@media only screen and (max-width: 900px) {

    .Service4-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 40px;
        font-size: 18px;
    }

    .Service4 {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 90%;
        padding: 0px;
        padding: 0px;
        font-size: 18px;
        text-align: center;
    }

    .img-containers4{
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Service-detail-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        height: 200px;
    }

    .Service-detail-image4 {
        margin: 0;
        border-radius: 20px;
        padding: 0;
        height: 420px;
        align-items: center;
        margin-bottom: 10px;
    }

}